import { styles } from "./styles";

/**
 * Helper function to render minMax data dynamically
 */
export const renderMinMax = (minMax, unit) => {
  if (minMax.low && minMax.high) {
    // Low and High Range
    return (
      <div className="min-max-section mb-3">
        <div className="d-flex justify-content-between">
          <span className="text-muted">Low</span>
          <span className="text-muted">High</span>
        </div>
        <div style={styles.rangeBar}>
          <div style={styles.activeRange}></div>
        </div>
        <div className="d-flex justify-content-between mt-1">
          <span className="text-muted">
            {minMax.low} {unit}
          </span>
          <span className="text-muted">
            {minMax.high} {unit}
          </span>
        </div>
      </div>
    );
  }

  // Normal Range
  if (minMax.normal) {
    return (
      <div className="min-max-section mb-3 text-center">
        <p className="text-muted">
          Normal: {minMax.normal} {unit}
        </p>
      </div>
    );
  }

  // Age-Group Specific Ranges
  return (
    <div className="min-max-section mb-3">
      {Object.entries(minMax)
        .reduce((acc, [ageGroup, range], index) => {
          if (index % 2 === 0) {
            // Start a new row every two items
            acc.push([]);
          }
          acc[acc.length - 1].push({ ageGroup, range });
          return acc;
        }, [])
        .map((row, rowIndex) => (
          <div key={rowIndex} className="d-flex mb-2">
            {row.map(({ ageGroup, range }) => (
              <div key={ageGroup} className="me-4 fs-6">
                <span
                  className="text-muted fw-bold"
                  style={{ fontSize: "0.75rem" }}
                >
                  {ageGroup}:
                </span>
                <span
                  className="text-muted ms-2"
                  style={{ fontSize: "0.75rem" }}
                >
                  {range}
                </span>
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};
