import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'services/api';

const PackagePage = ({ funnel }) => {
  const [packageDetails, setPackageDetails] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState('annual'); // Default to annual
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPackage = async () => {
      try {
        const response = await api.get(`/packages/${funnel.suggested_package}/`);
        setPackageDetails(response.data);
      } catch (error) {
        console.error('Error fetching package:', error);
      }
    };

    fetchPackage();
  }, [funnel.suggested_package]);

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  const handleRedirectToLogin = () => {
    localStorage.setItem('selectedPackageId', funnel.suggested_package);
    localStorage.setItem('selectedSubscription', selectedPlan)
    navigate('/login');
  };

  if (!packageDetails) {
    return <div className="p-6">Loading...</div>;
  }

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">{packageDetails.name}</h2>
      {packageDetails.photo && (
        <img
          src={packageDetails.photo}
          alt={packageDetails.name}
          className="w-full h-64 object-cover rounded-lg mb-4"
        />
      )}
      <h3 className="text-xl font-semibold mb-2">Included Products:</h3>
      <ul className="list-disc list-inside mb-4">
        {packageDetails.package_products.map((product) => (
          <li key={product.id} className="text-lg">
            {product.product.name}
          </li>
        ))}
      </ul>
      <div className="mt-6">
        <p className="text-lg font-semibold mb-4">
          Select your plan
        </p>
        <div className="flex flex-col space-y-4 w-full">
          <button
            className={`px-6 py-2 rounded-full w-full flex justify-between items-center ${
              selectedPlan === 'annual'
                ? 'bg-blue-500 text-white'
                : 'bg-blue-200 text-blue-500'
            }`}
            onClick={() => handlePlanSelect('annual')}
          >
            <span>Annual Subscription</span>
            <span>${packageDetails.annual_price}/year</span>
          </button>
          <button
            className={`px-6 py-2 rounded-full w-full flex justify-between items-center ${
              selectedPlan === 'monthly'
                ? 'bg-blue-500 text-white'
                : 'bg-blue-200 text-blue-500'
            }`}
            onClick={() => handlePlanSelect('monthly')}
          >
            <span>Monthly Subscription</span>
            <span>${packageDetails.monthly_price}/month</span>
          </button>
        </div>
        <div className="flex justify-center w-full mt-6 mb-8">
          <button
            className="px-6 py-2 bg-blue-400 text-white rounded-full w-1/2 flex justify-center items-center mb-8"
            onClick={handleRedirectToLogin}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default PackagePage;
