import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from 'services/api';
import QuestionPage from './QuestionPage';
import LandingPage from './LandingPage';
import PackagePage from '../PackagePage';
import PageTransition from '../PageTransition';

const FunnelPage = ({ setProgress }) => {
  const { funnelId } = useParams();
  const [funnelElements, setFunnelElements] = useState([]);
  const [currentElementIndex, setCurrentElementIndex] = useState(0);
  const [funnel, setFunnel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [, setProgressBarVisible] = useState(false);

  useEffect(() => {
    const fetchFunnel = async () => {
      try {
        const response = await api.get(`/funnels/${funnelId}/`);
        setFunnel(response.data);
        setFunnelElements(response.data.elements.sort((a, b) => a.order - b.order));
      } catch (error) {
        console.error('Error fetching funnel:', error);
      } finally {
        setLoading(false);
        // Show progress bar with fade-in
        setProgressBarVisible(true);
      }
    };

    fetchFunnel();
  }, [funnelId]);

  useEffect(() => {
    const totalSteps = funnelElements.length + 1; // Including the package page
    const progressValue = ((currentElementIndex + 1) / totalSteps) * 100;
    setProgress(progressValue);

    // Reset progress on unmount
    return () => {
      setProgress(0);
    };
  }, [currentElementIndex, funnelElements.length, setProgress]);

  const handleNext = () => {
    if (currentElementIndex < funnelElements.length - 1) {
      setCurrentElementIndex(currentElementIndex + 1);
    } else {
      // All elements completed, navigate to package page
      setCurrentElementIndex(currentElementIndex + 1);
    }
  };

  if (loading) {
    return <div className="p-6">Loading...</div>;
  }

  const currentElement = funnelElements[currentElementIndex];

  let content;

  if (currentElementIndex >= funnelElements.length) {
    // Show PackagePage
    content = <PackagePage funnel={funnel} />;
  } else if (currentElement.content_type === 'question') {
    content = (
      <QuestionPage
        questionId={currentElement.question}
        onNext={handleNext}
      />
    );
  } else if (currentElement.content_type === 'landing_page') {
    content = (
      <LandingPage
        landingPageId={currentElement.landing_page}
        onNext={handleNext}
      />
    );
  }

  return (
    <div>
      <PageTransition locationKey={currentElementIndex}>
        {content}
      </PageTransition>
    </div>
  );
};

export default FunnelPage;
