export const styles = {
    icon: {
        marginRight: "8px",
        width: "24px",
        height: "24px",
    },
    tooltipIcon: {
        marginLeft: "8px",
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
    },
    rangeBar: {
        position: "relative",
        height: "8px",
        backgroundColor: "#f0f0f0",
        borderRadius: "4px",
    },
    activeRange: {
        position: "absolute",
        height: "100%",
        backgroundColor: "#007bff",
        left: "0%",
        width: "100%",
        borderRadius: "4px",
    },
    chartContainer: {
        width: "100%",
        height: "200px",
    },
};
