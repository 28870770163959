import FunnelPage from 'components/funnels/FunnelPage';
import FunnelsList from 'components/funnels/FunnelsList';
import Header from 'components/Header';
import PageTransition from 'components/PageTransition';
import ProgressBar from 'components/ProgressBar';
import LoginPage from 'components/auth/LoginPage';
import Dashboard from 'components/dashboard';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

function App() {
  return (
    <Router>
      <MainContent />
    </Router>
  );
}

const MainContent = () => {
  const location = useLocation();
  const isFunnelPage = location.pathname.startsWith('/funnels/');
  const isLoginPage = location.pathname === '/login';
  const isRootPage = location.pathname === '/';
  // const isDashboardPage = location.pathname === '/dashboard';
  const [progress, setProgress] = useState(0);

  // Determine the padding class based on the route
  const paddingClass = isFunnelPage || isRootPage ? 'px-6' : 'px-1';
  const widthClass = isFunnelPage || isRootPage ? 'w-1/2' : 'w-2/3';

  return isLoginPage ? (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/dashboard" element={<Dashboard />} />
    </Routes>
  ) : (
    <div className={paddingClass}>
      <Header
        showProgressBar={isFunnelPage}
        progressBar={isFunnelPage && <ProgressBar progress={progress} />}
      />
      {/* Center the content and set its width to w-1/2 */}
      <div className="flex justify-center">
        <div className={`relative mt-4 ${widthClass}`}>
          <PageTransition locationKey={location.pathname}>
            <Routes location={location}>
              <Route path="/" element={<FunnelsList />} />
              <Route
                path="/funnels/:funnelId/*"
                element={<FunnelPage setProgress={setProgress} />}
              />
              <Route path="/dashboard" element={<Dashboard />} />
            </Routes>
          </PageTransition>
        </div>
      </div>
    </div>
  );
};

export default App;