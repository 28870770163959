import React from 'react';

const LandingPageType1 = ({ landingPage, onNext }) => {
  return (
    <div className="">
      <h2 className="text-2xl font-semibold mb-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-[#AEC9FF] to-[#1563FF]">
        {landingPage.title}
      </h2>
      {landingPage.photo && (
        <img
          src={landingPage.photo}
          alt={landingPage.title}
          className="w-full h-64 object-cover rounded-lg mb-4"
        />
      )}
      <div
        className="text-lg"
        dangerouslySetInnerHTML={{ __html: landingPage.body }}
      />
      <div className="flex justify-end mt-6">
        <button
          onClick={onNext}
          className="px-6 py-2 bg-selected text-white rounded-full"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default LandingPageType1;
