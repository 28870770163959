import React, { useEffect, useState } from 'react';
import api from 'services/api';

const QuestionPage = ({ questionId, onNext }) => {
  const [question, setQuestion] = useState(null);

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const response = await api.get(`/funnel-questions/${questionId}/`);
        setQuestion(response.data);
      } catch (error) {
        console.error('Error fetching question:', error);
      }
    };

    fetchQuestion();
  }, [questionId]);

  if (!question) {
    return <div className="p-6">Loading...</div>;
  }

  const handleOptionSelect = (optionId) => {
    // Retrieve the existing answers from localStorage
    const existingAnswers = JSON.parse(localStorage.getItem('answers')) || [];

    // Add the new answer to the array
    const newAnswer = { questionId, optionId };
    existingAnswers.push(newAnswer);

    // Save the updated answers back to localStorage
    localStorage.setItem('answers', JSON.stringify(existingAnswers));
    // Handle option selection logic
    // For now, we just move to the next page
    onNext();
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">{question.question}</h2>
      <div className="space-y-4">
        {question.options.map((option) => (
          <button
            key={option.id}
            className="flex items-center p-4 bg-gray-100 rounded-lg w-full border border-gray-300 hover:border-gray-500"
            onClick={() => handleOptionSelect(option.id)}
          >
            {option.photo && (
              <img
                src={option.photo}
                alt={option.text}
                className="w-12 h-12 mr-4 object-cover rounded-full"
              />
            )}
            <span className="text-lg">{option.text}</span>
          </button>
        ))}
      </div>
      <div className="flex justify-end mt-6">
        <button
          onClick={onNext}
          className="px-6 py-2 bg-selected text-white rounded-full"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default QuestionPage;
