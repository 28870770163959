import React, { useEffect, useState } from 'react';
import api from 'services/api';
import LandingPageType1 from './LandingPageType1';
import LandingPageType2 from './LandingPageType2';

const LandingPage = ({ landingPageId, onNext }) => {
  const [landingPage, setLandingPage] = useState(null);

  useEffect(() => {
    const fetchLandingPage = async () => {
      try {
        const response = await api.get(`/funnel-landing-pages/${landingPageId}/`);
        setLandingPage(response.data);
      } catch (error) {
        console.error('Error fetching landing page:', error);
      }
    };

    fetchLandingPage();
  }, [landingPageId]);

  if (!landingPage) {
    return <div className="p-6">Loading...</div>;
  }

  // Render based on page_type
  if (landingPage.page_type === 'type1') {
    return <LandingPageType1 landingPage={landingPage} onNext={onNext} />;
  } else if (landingPage.page_type === 'type2') {
    return <LandingPageType2 landingPage={landingPage} onNext={onNext} />;
  } else {
    // Default to type1 if page_type is unknown
    return <LandingPageType1 landingPage={landingPage} onNext={onNext} />;
  }
};

export default LandingPage;
