import React from 'react';

const ProgressBar = ({ progress }) => (
  <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
    <div
      className="h-full 
      rounded-full overflow-hidden
      bg-gradient-to-r from-[#AEC9FF] to-[#1563FF] 
      transition-width duration-500 ease-in-out"
      style={{ width: `${progress}%` }}
    ></div>
  </div>
);

export default ProgressBar;
