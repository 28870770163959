import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
  ReferenceDot,
} from "recharts";
import { styles } from "./styles";
import { renderMinMax } from "./utils";

const getBordersForTitle = (title) => {
  const borderMapping = {
    A1c: [
      { label: "Low", value: 4.0 },
      { label: "Good", value: 4.5 },
      { label: "Fair", value: 4.8 },
      { label: "High", value: 5.6 },
    ],
    "Fasting Insulin": [
      { label: "Low", value: 2.0 },
      { label: "Good", value: 4.0 },
      { label: "Fair", value: 6.0 },
      { label: "High", value: 8.0 },
    ],
    Estradiol: [
      { label: "Low", value: 10 },
      { label: "Good", value: 40 },
      { label: "High", value: 100 },
    ],
    Glucose: [
      { label: "Low", value: 70 },
      { label: "Good", value: 99 },
      { label: "Fair", value: 125 },
      { label: "High", value: 150 },
    ],
    "Total Cholesterol": [
      { label: "Desireable", value: 200 },
      { label: "Borderline High", value: 240 },
      { label: "High", value: 270 },
    ],
    Hdl: [
      { label: "Low", value: 40 },
      { label: "Good", value: 60 },
      { label: "Optimal", value: 80 },
    ],
    Ldl: [
      { label: "Optimal", value: 100 },
      { label: "Near Optimal", value: 130 },
      { label: "Borderline High", value: 160 },
      { label: "High", value: 190 },
      { label: "Very High", value: 220 },
    ],
    Triglycerides: [
      { label: "Normal", value: 150 },
      { label: "Borderline High", value: 200 },
      { label: "High", value: 500 },
      { label: "Very High", value: 600 },
    ],
    "Total Testosterone": [
      { label: "Low", value: 300 },
      { label: "Good", value: 800 },
      { label: "High", value: 900 },
    ],
    "Free Testosterone": [
      { label: "Low", value: 5 },
      { label: "Good", value: 15 },
      { label: "Fair", value: 25 },
      { label: "High", value: 30 },
    ],
    "Second Estradiol": [
      { label: "Low", value: 15 },
      { label: "Good", value: 60 },
      { label: "Fair", value: 100 },
      { label: "High", value: 120 },
    ],
    "Dhea S": [
      { label: "Low", value: 100 },
      { label: "Good", value: 200 },
      { label: "High", value: 300 },
    ],
  };
  return borderMapping[title] || [];
};

const getHoverColor = (value, borders) => {
  if (!borders || borders.length === 0) return "grey";

  for (let i = 0; i < borders.length - 1; i++) {
    if (value < borders[i + 1].value) {
      switch (borders[i].label) {
        case "Low":
          return "yellow";
        case "Optimal":
        case "Desireable":
        case "Near Optimal":
        case "Normal":
        case "Good":
          return "green";
        case "Borderline High":
        case "Fair":
          return "orange";
        case "Very High":
        case "High":
          return "red";
        default:
          return "grey";
      }
    }
  }
  return "red";
};

const getQualityLabel = (value, borders) => {
  if (!borders || borders.length === 0 || value === null) return null;

  for (let i = 0; i < borders.length - 1; i++) {
    if (value < borders[i + 1].value) {
      return borders[i].label;
    }
  }

  return borders[borders.length - 1].label;
};

const Card = ({
  icon,
  title,
  value,
  unit,
  subtitle,
  chartData,
  tooltipText,
  minMax,
}) => {
  const [hoveredValue, setHoveredValue] = useState(null);

  const handleMouseMove = (state) => {
    if (state && state.activePayload && state.activePayload.length) {
      setHoveredValue(state.activePayload[0].value);
    } else {
      setHoveredValue(null);
    }
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      setHoveredValue(payload[0].value);
    } else {
      setHoveredValue(null);
    }
    return null;
  };

  const borders = getBordersForTitle(title);

  const xAxisDomain =
    borders.length > 0
      ? [
          Math.min(...borders.map((border) => border.value)) - 0.05*(Math.min(...borders.map((border) => border.value))),
          Math.max(...borders.map((border) => border.value)) + 0.05*Math.max(...borders.map((border) => border.value)),
        ]
      : [0, 10];

  const qualityLabel = getQualityLabel(hoveredValue, borders);

  // Intervals for top labels (areas between borders)
  const intervals = [];
  for (let i = 0; i < borders.length - 1; i++) {
    const start = borders[i].value;
    const end = borders[i + 1].value;
    const midpoint = (start + end) / 2;
    intervals.push({ label: borders[i].label, x: midpoint });
  }
  if (borders.length > 0) {
    const lastBorder = borders[borders.length - 1].value;
    const end = xAxisDomain[1];
    const midpoint = (lastBorder + end) / 2;
    intervals.push({ label: borders[borders.length - 1].label, x: midpoint });
  }

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title d-flex align-items-center">
          {icon && <img src={icon} alt={title || "Icon"} style={styles.icon} />}
          <span>{title}</span>
          {tooltipText && (
            <span style={styles.tooltipIcon} title={tooltipText} role="tooltip">
              <i className="fa-solid fa-circle-question blue-icon"></i>
            </span>
          )}
        </h5>

        <h2 className="text-primary">
          {hoveredValue !== null ? (
            <>
              {hoveredValue}
              {unit}{" "}
              <span
                style={{ marginLeft: "5px", color: "black", fontSize: "15px" }}
              >
                {qualityLabel || ""}
              </span>
            </>
          ) : (
            <>
              {value} {unit}
            </>
          )}
        </h2>

        {minMax ? (
          ["Dhea", "Igf 1"].includes(title) ? (
            renderMinMax(minMax, unit)
          ) : (
            <div style={{ width: "100%", height: 100, marginBottom: -60 }}>
              <ResponsiveContainer>
                <LineChart margin={{ left: 30, right: 30 }}>
                  <XAxis
                    type="number"
                    dataKey="value"
                    domain={xAxisDomain}
                    hide
                  />
                  <YAxis hide />
                  <CartesianGrid horizontal={false} vertical={false} />
                  <ReferenceLine y={0.5} stroke="#ccc" ifOverflow="extendDomain" />

                  {/* Top labels for intervals */}
                  {intervals.map((interval, i) => (
                    <ReferenceDot
                      key={i}
                      x={interval.x}
                      y={0.5}
                      r={0}
                      isFront
                      label={{
                        value: interval.label,
                        position: "top",
                        fontSize: 10,
                        fill: "#666",
                      }}
                      fill="transparent"
                      stroke="transparent"
                    />
                  ))}

                  {/* Borders: show numeric and label below plus vertical line */}
                  {borders.map((border, index) => (
                    <React.Fragment key={index}>
                      {/* Vertical line at the border */}
                      <ReferenceLine
                        segment={[
                          { x: border.value, y: 0.51 },
                          { x: border.value, y: 0.49 },
                        ]}
                        stroke="#999"
                        strokeWidth={2}
                      />
                      {/* Numeric value of the border at bottom */}
                      <ReferenceDot
                        x={border.value}
                        y={0.5}
                        r={0}
                        isFront
                        fill="transparent"
                        stroke="transparent"
                        label={{
                          value: border.value.toString(),
                          position: "bottom",
                          fontSize: 10,
                          fill: "#666",
                        }}
                      />
                    </React.Fragment>
                  ))}

                  {/* Hovered value indicator */}
                  {hoveredValue !== null && (
                    <ReferenceDot
                      x={hoveredValue}
                      y={0.5}
                      r={5}
                      fill={getHoverColor(hoveredValue, borders)}
                      stroke="#fff"
                      isFront
                    />
                  )}
                </LineChart>
              </ResponsiveContainer>
            </div>
          )
        ) : null}

        <hr style={{ border: "1px solid #ccc", margin: "10px 0" }} />
        {subtitle && <p className="text-secondary">{subtitle}</p>}
        <div style={styles.chartContainer}>
          {chartData && chartData.length > 0 ? (
            <ResponsiveContainer width="100%">
              <LineChart
                data={chartData}
                margin={{ left: -30, right: 10, top: 10, bottom: 10 }}
                onMouseMove={handleMouseMove}
                onMouseLeave={() => setHoveredValue(null)}
              >
                <CartesianGrid stroke="#ccc" />
                <XAxis
                  dataKey="date"
                  axisLine={{ stroke: "#ddd" }}
                  tick={{ fontSize: 11 }}
                  padding={{ left: 10, right: 10 }}
                />
                <YAxis
                  axisLine={{ stroke: "#ddd" }}
                  tick={{ fontSize: 11 }}
                  padding={{ top: 10, bottom: 10 }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Line
                  type="monotone"
                  dataKey="value"
                  stroke="#8884d8"
                  dot={{ stroke: "#0000FF", strokeWidth: 2 }}
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <p style={{ textAlign: "center", color: "#888" }}>No data available</p>
          )}
        </div>
      </div>
    </div>
  );
};

Card.defaultProps = {
  icon: null,
  title: "Untitled",
  value: "N/A",
  unit: "",
  subtitle: "",
  chartData: [],
  tooltipText: "",
  minMax: null,
};

export default Card;
