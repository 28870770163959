import React, { useEffect, useState } from 'react';
import api from 'services/api';
import { useNavigate } from 'react-router-dom';

const FunnelsList = () => {
  const [funnels, setFunnels] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFunnels = async () => {
      try {
        const response = await api.get('/funnels/');
        setFunnels(response.data.results);
      } catch (error) {
        console.error('Error fetching funnels:', error);
      }
    };

    fetchFunnels();

    // Clear local storage
    localStorage.clear();

  }, []);

  const handleSelectFunnel = (funnelId) => {
    // save to local storage
    localStorage.setItem('selectedFunnelId', funnelId);
    navigate(`/funnels/${funnelId}`);
  };

  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4">Select a Funnel</h2>
      <div className="grid grid-cols-1 gap-4">
        {funnels.map((funnel) => (
          <button
            key={funnel.id}
            className="relative h-40 rounded-lg overflow-hidden bg-cover bg-center"
            style={{ backgroundImage: `url(${funnel.photo})` }}
            onClick={() => handleSelectFunnel(funnel.id)}
          >
            <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <span className="text-white text-xl font-semibold">{funnel.name}</span>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default FunnelsList;
