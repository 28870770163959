import React, { useEffect, useState } from "react";
import Card from "./Card/Card";
import {
  getIcon,
  getLabIcon,
  getLatestValue,
  getMinMax,
  getSubtitles,
  getLabSubtitles,
  getLabResultTooltipText,
  getBodyCompositionTooltipText,
  getUnit,
  formatTitle,
} from "./utils";
import api from "../../services/api";
import "bootstrap/dist/css/bootstrap.min.css";

const Dashboard = () => {
  const [bodyCompositions, setBodyCompositions] = useState(null);
  const [labResults, setLabResults] = useState(null);
  const [unauthorized, setUnauthorized] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const fetchBodyCompositions = async () => {
      try {
        const body_composition_response = await api.get(`/body-compositions/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setBodyCompositions(body_composition_response.data || {});
      } catch (error) {
        console.error("Error fetching body compositions:", error);
        if (error.response && error.response.status === 401) {
          setUnauthorized(true);
        } else {
          setBodyCompositions({});
        }
      }
    };

    const fetchLabResults = async () => {
      try {
        const lab_result_response = await api.get(`/lab-results/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setLabResults(lab_result_response.data || {});
      } catch (error) {
        console.error("Error fetching lab results:", error);
        if (error.response && error.response.status === 401) {
          setUnauthorized(true);
        } else {
          setLabResults({});
        }
      }
    };

    fetchBodyCompositions();
    fetchLabResults();
  }, []);

  if (unauthorized) {
    window.location.href = '/login';
  }

  // If neither bodyCompositions nor labResults are set, show a generic message
  if (!bodyCompositions && !labResults) {
    return <div>There is no data.</div>;
  }

  const bodyCompositionMetrics = Object.keys(bodyCompositions || {});
  const labResultMetrics = Object.keys(labResults || {});

  // Check if all body composition metrics are empty
  const allBodyCompositionEmpty = bodyCompositionMetrics.length > 0
    && bodyCompositionMetrics.every(
      (metricKey) =>
        Array.isArray(bodyCompositions[metricKey]) &&
        bodyCompositions[metricKey].length === 0
    );

  // Check if all lab result metrics are empty
  const allLabResultsEmpty = labResultMetrics.length > 0
    && labResultMetrics.every(
      (metricKey) =>
        Array.isArray(labResults[metricKey]) &&
        labResults[metricKey].length === 0
    );

  return (
    <>
      {/* Body Composition Section */}
      <div className="container mx-0 px-0">
        <h1 className="text-primary">Body Composition</h1>
        {allBodyCompositionEmpty || bodyCompositionMetrics.length === 0 ? (
          <p>No body composition data available.</p>
        ) : (
          <>
            <p>
              A detailed breakdown of your body's components to help you better
              understand your health and fitness.
            </p>
            <div className="row">
              {bodyCompositionMetrics.map((metricKey) => (
                <div key={metricKey} className="col-12 col-sm-6 col-lg-4 mb-4">
                  <Card
                    icon={getIcon(metricKey)}
                    title={formatTitle(metricKey)}
                    value={getLatestValue(bodyCompositions[metricKey])}
                    unit={getUnit(metricKey)}
                    subtitle={getSubtitles(metricKey)}
                    tooltipText={getBodyCompositionTooltipText(metricKey)}
                    chartData={bodyCompositions[metricKey]}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      <hr style={{ border: "1px solid #ccc", margin: "10px 0" }} />

      {/* Lab Results Section */}
      <div className="container mx-0 px-0">
        <h1 className="text-primary">Lab Results</h1>
        {allLabResultsEmpty || labResultMetrics.length === 0 ? (
          <p>No lab results data available.</p>
        ) : (
          <>
            <p>Comprehensive insights into your body’s internal health.</p>
            <div className="row">
              {labResultMetrics.map((metricKey) => (
                <div key={metricKey} className="col-12 col-sm-6 col-lg-4 mb-4">
                  <Card
                    icon={getLabIcon(metricKey)}
                    title={formatTitle(metricKey)}
                    value={getLatestValue(labResults[metricKey])}
                    unit={getUnit(metricKey)}
                    subtitle={getLabSubtitles(metricKey)}
                    chartData={labResults[metricKey]}
                    tooltipText={getLabResultTooltipText(metricKey)}
                    minMax={getMinMax(metricKey) || []}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Dashboard;
