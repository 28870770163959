import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginPage.css";
import { loadStripe } from "@stripe/stripe-js";
import api from "services/api";
import LoginPage from "./LoginPage";

const SignUpPage = () => {
  const navigate = useNavigate();
  const [showLogin, setShowLogin] = useState(false);
  const [isEmailSignup, setIsEmailSignup] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email) =>
    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email);

  const handleEmailSignup = async (e) => {
    e.preventDefault();
    const { email, password, confirmPassword } = formData;

    if (!email || !password || !confirmPassword) {
      setErrorMessage("All fields are required.");
      return;
    }

    if (!validateEmail(email)) {
      setErrorMessage("Invalid email format.");
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    try {
      // Retrieve all stored variables from localStorage
      const storedData = { ...localStorage }; // Copies all localStorage entries
      const parsedData = {};

      // Parse JSON values if applicable
      for (const [key, value] of Object.entries(storedData)) {
        try {
          parsedData[key] = JSON.parse(value); // Try to parse as JSON
        } catch {
          parsedData[key] = value; // Fallback to raw value
        }
      }

      // Include storedVariables in the payload
      const payload = {
        ...formData,
        storedVariables: parsedData,
      };

      const response = await api.post("/email-signup/", payload, {
        validateStatus: (status) => status >= 200 && status < 500,
      });

      if (response.status !== 200) {
        if (response.data && response.data.error) {
          throw new Error(response.data.error);
        }
        throw new Error("Signup failed. Please try again.");
      }

      setFormData({ name: "", email: "", password: "", confirmPassword: "" });
      setErrorMessage("");

      const { accessToken, has_subscription, selectedPackageId } =
        response.data;

      if (!accessToken) {
        throw new Error("Signup successful but no access token received.");
      }

      // Set the access token
      localStorage.setItem("accessToken", accessToken);

      // Step 3: Redirect to the dashboard if the user has a subscription
      if (has_subscription) {
        window.location.href = "/dashboard";
        return;
      }

      // Step 4: Create a checkout session for the selected package if needed
      if (selectedPackageId) {
        try {
          const buyPackageResponse = await api.post(
            `/create_checkout/`,
            {
              type: "package",
              ids: [selectedPackageId].map(String), // Convert IDs to strings
            },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`, // Add the token to the Authorization header
              },
            }
          );

          if (buyPackageResponse.status === 200) {
            const stripeSessionId = buyPackageResponse.data.id;
            const stripe = await loadStripe(
              "pk_test_51QMrCnRxpQnNGKMCNwbm910J0VJ9cpUL7JdEdmJTdoBF3DqQGxqKTnKTxHYKG1bNnCoFo9q9eD4xDvDUUEImE6Vp00qeWMqAep"
            );
            await stripe.redirectToCheckout({ sessionId: stripeSessionId });
            return;
          } else {
            console.error(
              "Failed to initiate package purchase:",
              await buyPackageResponse.text()
            );
          }
        } catch (checkoutError) {
          console.error("Error creating checkout session:", checkoutError);
        }
      }

      // If no subscription or checkout required, navigate to the dashboard
      navigate("/dashboard");
    } catch (error) {
      console.error("Error during signup:", error);
      setErrorMessage(error.message);
    }
  };

  if (showLogin) {
    return <LoginPage />;
  }

  if (isEmailSignup) {
    return (
      <div className="login-container">
        <div className="login-left">
          <img
            src="/running_group.png"
            alt="Fitness"
            className="background-image"
          />
        </div>
        <div className="login-right">
          <h1 className="text-primary mb-3">HEALTHITRACK</h1>
          <h2 className="text-black fw-bold mb-4">
            Let's get your account set up
          </h2>
          <form
            onSubmit={handleEmailSignup}
            className="w-100"
            style={{ maxWidth: "400px" }}
          >
            <div className="mb-3">
              <label htmlFor="email" className="form-label fw-bold">
                Your email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                className="form-control"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="password" className="form-label fw-bold">
                Your password
              </label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="password"
                value={formData.password}
                onChange={handleInputChange}
                required
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="confirmPassword" className="form-label fw-bold">
                Repeat password
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                placeholder="password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                required
                className="form-control"
              />
            </div>
            {errorMessage && (
              <p className="text-danger small text-center">{errorMessage}</p>
            )}
            <button type="submit" className="btn btn-dark w-100">
              Create account
            </button>
          </form>
          <p className="mt-3">
            Already have an account?{" "}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowLogin(true);
              }}
              className="text-decoration-none text-primary"
            >
              Log in
            </a>
          </p>
          <p>
            Want to try other options?{" "}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsEmailSignup(false);
              }}
              className="text-decoration-none text-secondary"
            >
              Back
            </a>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="login-container">
      <div className="login-left">
        <img
          src="/running_group.png"
          alt="Fitness"
          className="background-image"
        />
      </div>
      <div className="login-right">
        <h1 className="brand-name">HEALTHITRACK</h1>
        <h2 className="welcome-text">Let's get your account set up</h2>
        <div className="login-buttons">
          <button className="login-button google">
            <img src="/google.png" alt="Google logo" />
            Sign up with Google
          </button>
          <button className="login-button facebook">
            <img src="/facebook.png" alt="Facebook logo" />
            Sign up with Facebook
          </button>
          <button
            className="login-button email"
            onClick={() => setIsEmailSignup(true)}
          >
            <img src="/email.png" alt="Email logo" />
            Sign up with Email
          </button>
        </div>
        <p className="signup-link">
          Already have an account?{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShowLogin(true);
            }}
          >
            Log in
          </a>
        </p>
      </div>
    </div>
  );
};

export default SignUpPage;
