// src/components/LandingPageType2.js
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import './LandingPageType2.css';

const LandingPageType2 = ({ landingPage, onNext }) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowContent(true);
    }, 500);
  }, [landingPage.photo]);

  const handleExit = () => {
    setShowContent(false); // Trigger exit animation
    setTimeout(() => {
      onNext();
    }, 500); // Match this duration with the CSS transition duration
  };

  return (
    <div className="fixed inset-0 bg-white">
      {/* Background Image */}
      <div
        className={`absolute inset-0 background-image ${showContent ? 'loaded' : 'exiting'}`}
        style={{
          backgroundImage: `url(${landingPage.photo})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'brightness(0.7)',
        }}
      ></div>
      {/* Overlay */}
      <div className={`absolute inset-0 bg-black bg-opacity-50 background-image ${showContent ? 'loaded' : 'exiting'}`}></div>
      {/* Content */}
      <CSSTransition
        in={showContent}
        timeout={500}
        classNames="slide-fade"
        unmountOnExit
      >
        <div className="fixed inset-0 flex items-center justify-center text-center">
          <div className="relative z-10">
            <h2 className="text-xl font-semibold mb-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-[#AEC9FF] to-[#1563FF]">
              {landingPage.title}
            </h2>
            <div
              className="text-lg text-white mb-6"
              dangerouslySetInnerHTML={{ __html: landingPage.body }}
            />
            <button
              onClick={handleExit}
              className="px-6 py-2 bg-white bg-opacity-20 text-black rounded-full backdrop-filter backdrop-blur-md"
            >
              Continue
            </button>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default LandingPageType2;
