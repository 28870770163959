import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "healthitrack.firebaseapp.com",
    projectId: "healthitrack-afc8f",
    storageBucket: "healthitrack-afc8f.firebasestorage.app",
    messagingSenderId: "261292360933",
    appId: "1:261292360933:web:fd3c1ec72ff90aff90400c",
    measurementId: "G-Y3QDQ44P21"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
