import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import api from "services/api";
import { signInWithGoogle } from "../../authUtils";
import SignUpPage from "./SignUpPage";
import "./LoginPage.css";

const LoginPage = () => {
  const [showSignUp, setShowSignUp] = useState(false);
  const [isEmailLogin, setIsEmailLogin] = useState(false);
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errorMessage, setErrorMessage] = useState("");

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithGoogle();
      // Get the Firebase ID token
      const idToken = await result.user.getIdToken();
      const user = result.user;
      console.log("Logged in user:", user);

      // Retrieve all stored variables from localStorage
      const storedData = { ...localStorage }; // Copies all localStorage entries
      const parsedData = {};

      // Parse JSON values if applicable
      for (const [key, value] of Object.entries(storedData)) {
        try {
          parsedData[key] = JSON.parse(value); // Try to parse as JSON
        } catch {
          parsedData[key] = value; // Fallback to raw value
        }
      }

      // Combine the token and localStorage data in the request payload
      const payload = {
        token: idToken,
        storedVariables: parsedData,
      };

      const response = await api.post("/firebase-login/", payload);
      if (response.status === 200) {
        // Get the selected package from the response or local storage
        const selectedPackageId = response.data.selectedPackageId;
        const has_subscription = response.data.has_subscription;
        const access_token = response.data.accessToken;

        // store the access token in local storage
        localStorage.setItem("accessToken", access_token);

        // if has subscription redirect to dashboard
        if (has_subscription) {
          window.location.href = "/dashboard";
        }

        // Ensure the package is defined
        if (selectedPackageId) {
          // get the selected package from the local storage
          const buyPackageResponse = await api.post(
            `/create_checkout/`,
            {
              type: "package",
              ids: [selectedPackageId].map(String), // Convert IDs to strings,
            },
            {
              headers: {
                Authorization: `Bearer ${access_token}`, // Add the token to the Authorization header
              },
            }
          );
          if (buyPackageResponse.status === 200) {
            const stripId = buyPackageResponse.data.id;
            const stripe = await loadStripe(
              "pk_test_51QMrCnRxpQnNGKMCNwbm910J0VJ9cpUL7JdEdmJTdoBF3DqQGxqKTnKTxHYKG1bNnCoFo9q9eD4xDvDUUEImE6Vp00qeWMqAep"
            );
            return stripe.redirectToCheckout({ sessionId: stripId });
          } else {
            console.error(
              "Failed to initiate package purchase:",
              await buyPackageResponse.text
            );
          }
        }

        console.log("Backend verification successful:", response.data);
        // Handle successful login, e.g., store tokens or navigate
      } else {
        console.error("Backend verification failed:", response.data);
      }
    } catch (error) {
      console.error("Error logging in with Google:", error);
    }
  };

  const handleEmailLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      // Retrieve all stored variables from localStorage
      const storedData = { ...localStorage }; // Copies all localStorage entries
      const parsedData = {};
  
      // Parse JSON values if applicable
      for (const [key, value] of Object.entries(storedData)) {
        try {
          parsedData[key] = JSON.parse(value); // Try to parse as JSON
        } catch {
          parsedData[key] = value; // Fallback to raw value
        }
      }
  
      // Step 1: Send login credentials along with storedVariables to the email-login API
      const payload = {
        ...formData,
        storedVariables: parsedData,
      };
  
      const response = await api.post("/email-login/", payload, {
        validateStatus: (status) => status >= 200 && status < 500,
      });
  
      if (response.status === 200) {
        const { accessToken, has_subscription, selectedPackageId } = response.data;
  
        // Step 2: Store the access token in local storage
        localStorage.setItem("accessToken", accessToken);
  
        // Step 3: Redirect to the dashboard if the user has a subscription
        if (has_subscription) {
          window.location.href = "/dashboard";
          return;
        }
  
        // Step 4: Create a checkout session for the selected package if needed
        if (selectedPackageId) {
          try {
            const buyPackageResponse = await api.post(
              `/create_checkout/`,
              {
                type: "package",
                ids: [selectedPackageId].map(String), // Convert IDs to strings
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`, // Add the token to the Authorization header
                },
              }
            );
  
            if (buyPackageResponse.status === 200) {
              const stripeSessionId = buyPackageResponse.data.id;
              const stripe = await loadStripe(
                "pk_test_51QMrCnRxpQnNGKMCNwbm910J0VJ9cpUL7JdEdmJTdoBF3DqQGxqKTnKTxHYKG1bNnCoFo9q9eD4xDvDUUEImE6Vp00qeWMqAep"
              );
              await stripe.redirectToCheckout({ sessionId: stripeSessionId });
            } else {
              console.error(
                "Failed to initiate package purchase:",
                await buyPackageResponse.text()
              );
            }
          } catch (checkoutError) {
            console.error("Error creating checkout session:", checkoutError);
          }
        }
      } else {
        setErrorMessage("Invalid email or password.");
      }
    } catch (error) {
      console.error("Error logging in with email:", error);
      setErrorMessage("Failed to log in. Please try again.");
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  if (showSignUp) {
    return <SignUpPage />;
  }

  return (
    <div className="login-container">
      <div className="login-left">
        <img
          src="/running_group.png"
          alt="Fitness"
          className="background-image"
        />
      </div>
      <div className="login-right">
        <h1 className="brand-name">HEALTHITRACK</h1>
        <h2 className="welcome-text">Welcome back</h2>
        {!isEmailLogin ? (
          <div className="login-buttons">
            <button className="login-button google" onClick={handleGoogleLogin}>
              <img src="/google.png" alt="Google logo" />
              Log in with Google
            </button>
            <button className="login-button facebook">
              <img src="/facebook.png" alt="Facebook logo" />
              Log in with Facebook
            </button>
            <button
              className="login-button email"
              onClick={() => setIsEmailLogin(true)}
            >
              <img src="/email.png" alt="Email logo" />
              Log in with Email
            </button>
          </div>
        ) : (
          <form onSubmit={handleEmailLoginSubmit} className="login-form">
            <div className="form-group fw-bold">
              <label htmlFor="email">Enter your email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                className="form-control"
              />
            </div>
            <div className="form-group mt-3 fw-bold">
              <label htmlFor="password">Enter your password</label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="password"
                value={formData.password}
                onChange={handleInputChange}
                required
                className="form-control"
              />
            </div>
            {errorMessage && (
              <p className="text-danger small">{errorMessage}</p>
            )}
            <button type="submit" className="btn btn-dark w-100 mt-3">
              Login
            </button>
            <button
              type="button"
              className="btn btn-link w-100 mt-2"
              onClick={() => setIsEmailLogin(false)}
            >
              Back
            </button>
          </form>
        )}
        <p className="signup-link">
          First time here?{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShowSignUp(true);
            }}
          >
            Create an account
          </a>
        </p>
      </div>
    </div>
  );
};

export default LoginPage;
