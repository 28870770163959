import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './pageTransition.css';

const PageTransition = ({ children, locationKey }) => (
  <TransitionGroup component={null}>
    <CSSTransition
      key={locationKey}
      timeout={500}
      classNames="slide"
    >
      <div className="absolute inset-0 w-full h-full">
        {children}
      </div>
    </CSSTransition>
  </TransitionGroup>
);

export default PageTransition;
